<router>
{
    name: 'Users',
}
</router>
<template lang="pug">
v-container
    v-row
        v-col
            UserList

</template>
<script>

import UserList from '@/components/salestool/userlist.vue';
export default {
    meta: {
        role: 'sat'
    },
    components: {
        UserList
    },
    data () {
        return {
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
        window.satusersvm = this;
    }
};
</script>

<style scoped>
</style>
