<template lang="pug">
.userList
    v-card(light, flat)
        v-card-subtitle
            v-text-field(
                v-model="search"
                prepend-inner-icon="search"
                label="Search"
                single-line
                hide-details
                clearable, clear-icon='clear'
                color="brand",
                outlined, dense, light, solo, rounded, flat
            )
        v-card-text
            v-data-table.userTable.striped.hoverable(:items='users', :headers='headers', :search="search", dense)
                template(v-slot:item.admin='{item, value}')
                    v-checkbox(
                        dense,
                        color="brand",
                        v-model="item.admin",
                        :disabled="!isEditingUser(item)"
                    )
                template(v-slot:item.role='{item, value}')
                    v-select(
                        v-model="item.role",
                        dense,
                        :items="allRoles",
                        item-text="name",
                        item-value="value",
                        :disabled="!isEditingUser(item)",
                    )
                template(v-slot:item.email='{item, value}')
                    span(
                        v-if="!isEditingUser(item)"
                    ) {{ item.email }}
                    v-text-field(
                        v-if="isEditingUser(item) && editingUser",
                        v-model="editingUser.username",
                        @keyup.esc="cancelEditUser",
                        autofocus,
                        dense,
                        label="Add User",
                        color="brand",
                        :suffix="emailAppend",
                        single-line,
                        @keyup="handleEditUserKeyUp",
                        :error-messages="editUserErrors",
                    )
                template(v-slot:item.release_channel='{item, value}')
                    v-select(
                        v-model="item.release_channel",
                        dense,
                        :items="releaseChannels",
                        item-text="name",
                        item-value="value",
                        :disabled="!isEditingUser(item)"
                    )
                template(v-slot:item.user_controls='{item, value}')
                    .editButtons.d-flex
                        v-btn.saveUser(
                            small,
                            icon,
                            @click='saveUser(item)',
                            title='Save user'
                            v-if='isEditingUser(item)'
                        )
                            v-icon save
                        v-btn.deleteUser(
                            small,
                            icon,
                            @click="editUser(item)",
                            title="Edit",
                            v-if="!isEditingUser(item)"
                        )
                            v-icon(small) edit

                        v-btn(v-else, small, icon, @click="cancelEditUser", title='Cancel Edit')
                            v-icon(small) close
                        v-btn.deleteUser(
                            small,
                            icon,
                            title="Delete",
                            @click="confirmDelete(item)"
                        )
                            v-icon(small, color="red") delete
                template(v-slot:body.append)
                    tr
                        td(colspan='6')
                            v-text-field(
                                v-model="newUserName",
                                dense,
                                label="Add User",
                                color="brand",
                                suffix="@dorsettcontrols.com",
                                single-line,
                                @keyup="handleEmailKeyUp",
                                @keyup.enter="saveNewUser(newUserName)",
                                :error-messages="newUserErrors"
                            )
                                template(v-slot:append-outer)
                                    v-btn(
                                        icon,
                                        small,
                                        color="green",
                                        @click="saveNewUser(newUserName)",
                                        title="Save",
                                        :disabled="newUserErrors.length > 0 || newUserName === ''"
                                    )
                                        v-icon save

        v-overlay(absolute, :value="busy")
            .text-h6 Please Wait...
            v-progress-linear(indeterminate)

    ConfirmDialog(
        :show="confirmDialog",
        :confirmText="confirmText",
        @cancel="cancelDelete",
        @confirm="deleteUser",
        :width="400"
    )

    v-snackbar(v-model="showAlert", dark, :timeout="3000") {{ message }}
</template>

<script>
import _ from "lodash";
import ConfirmDialog from "@/components/utils/ConfirmDialog";
import dti from "dti";

export default {
    components: {
        ConfirmDialog,
    },
    data () {
        return {
            search: "",
            dti,
            accessKey: "satusers",
            emailAppend: "@dorsettcontrols.com",
            users: [],
            newUserName: "",
            newUserErrors: [],
            editUserErrors: [],
            editingUser: null,
            emailRegEx:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/,

            hasChanges: false,
            confirmDialog: false,
            deletingUser: null,
            allRoles: [
                {
                    name: "Tech",
                    value: "tech",
                },
                {
                    name: "Sales Rep",
                    value: "sales_rep",
                },
                {
                    name: "Sales Manager",
                    value: "sales_manager",
                },
                {
                    name: "Back Office",
                    value: "back_office",
                },
                {
                    name: "Project Manager",
                    value: "project_manager"
                }
            ],
            releaseChannels: [
                {
                    name: "Alpha",
                    value: "alpha",
                },
                {
                    name: "Beta",
                    value: "beta",
                },
                {
                    name: "Latest",
                    value: "latest",
                },
            ],
            headers: [
                {
                    value: 'name',
                    text: 'Name',
                },
                {
                    value: 'email',
                    text: 'Email',
                },
                {
                    value: 'role',
                    text: 'Role',
                },
                {
                    value: 'admin',
                    text: 'Admin'
                },
                {
                    value: 'release_channel',
                    text: 'Release Channel'
                },
                {
                    value: 'user_controls',
                    text: '',
                    width: 100
                }
            ],
            message: "",
        };
    },
    methods: {
        getUserList () {
            this.socketEmit("getUserList", null, (users) => {
                for (const user of users) {
                    user.name = this.calculateName(user);
                }
                this.users = users;
            });
        },
        handleEmailKeyUp () {
            this.newUserErrors = this.validateEmail(this.newUserName);
        },
        handleEditUserKeyUp () {
            this.editUserErrors = this.validateEmail(
                this.editingUser.username || ""
            );
        },
        validateEmail (email = this.newUserName) {
            if (!email) {
                return [];
            }

            let ret = [];
            if (!email.match(this.emailRegEx)) {
                ret = ["Invalid Email"];
            } else if (this.usernames.includes(email)) {
                ret = ["Email already exists"];
            }
            return ret;
        },
        editUser (user) {
            this.editUserErrors = [];
            this.originalUserData = _.cloneDeep(user);
            this.editingUser = _.cloneDeep(user);
        },
        cancelEditUser () {
            const userObject = this.users.find(u => u.username === this.originalUserData.username);
            if (userObject) {
                Object.assign(userObject, this.originalUserData);
            }
            this.editingUser = null;
        },
        isEditingUser (user) {
            return this.editingUser && this.editingUser.id === user.id;
        },
        async doEditUser (user) {
            let result = await this.sendCommand({
                action: "updateUser",
                parameters: user,
            });
            if (result) {
                this.message = `"${user.username}" updated`;
            } else {
                console.log("error updating user");
                this.message = `Error updating "${user.username}"`;
            }
        },
        saveUser () {
            let user = this.editingUser;
            let userToEdit = this.users.find((u) => {
                return u.id === user.id;
            });
            if (userToEdit) {
                let oldUsername = userToEdit.username;
                userToEdit.username = user.username;
                userToEdit.email = userToEdit.email.replace(oldUsername, user.username);
                this.doEditUser(userToEdit);
            }
            this.editingUser = null;
        },
        async saveNewUser (username = this.newUserName) {
            // check that user exists
            this.message = "";
            if (username) {
                const user = {
                    username,
                    email: username + this.emailAppend,
                    id: dti.makeuuid(),
                };
                const result = await this.sendCommand({
                    action: "addUser",
                    parameters: user,
                    returnResult: true
                });
                this.newUserName = "";
                if(result.error){
                    this.message = `${result.error} for "${username}" because ${result.details}`
                } else {
                    this.message = `"${username}" added successfully`;
                    this.getUserList();
                }

            }
        },
        confirmDelete (user) {
            this.deletingUser = user;
            this.confirmDialog = true;
        },
        async deleteUser () {
            let idx = this.users.findIndex((u) => {
                return u.id === this.deletingUser.id;
            });

            if (idx >= 0) {
                let result = await this.sendCommand({
                    action: "deleteUser",
                    parameters: {
                        id: this.deletingUser.id,
                    },
                });
                if (result) {
                    console.log("deleted user");
                    this.users.splice(idx, 1);
                    this.hasChanges = true;
                    this.message = `"${this.deletingUser.username}" deleted successfully`;
                } else {
                    console.log("error deleting user");
                    this.message = `Error deleting "${this.deletingUser.username}"`;
                }
            }
            this.confirmDialog = false;
        },
        cancelDelete () {
            this.deletingUser = null;
            this.confirmDialog = false;
        },
        calculateName (user) {
            if (user.first_name && user.last_name) {
                return `${user.first_name} ${user.last_name}`;
            }
            return `User hasn't logged in`;
        }
    },
    computed: {
        usernames () {
            return this.users.map((u) => u.username);
        },
        confirmText () {
            const {username} = this.deletingUser || {};
            return `Are you sure you want to delete the user "${username}"?`;
        },

        showAlert: {
            get () {
                return this.message !== "";
            },
            set (val) {
                if (val === false) {
                    this.message = "";
                }
            },
        },
    },
    mounted () {
        this.getUserList();
        window.satuserlistvm = this;
    },
};
</script>

<style lang="scss" scoped>
.editButtons {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
}
</style>

<style lang="scss">
.userList {
    .userTable {
        td {
            position: relative;
        }
    }

    .textInput {
        width: 50px;
    }
}
</style>
